import {
  IonApp,
  IonCol,
  IonContent,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import { useEffect, useState } from "react";

import Article from "./components/articles/views/Article";
import Category from "./components/articles/views/Category";
import Header from "./components/metadata/views/Header";
import Head from "./components/metadata/views/Head";
import Navigation from "./components/navigation/views/Navigation";
import Registration from "./components/registration/views/Registration";
import RegistrationList from "./components/registration/views/RegistrationList";

import Carbonator from "carbonator";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import Beer from "./components/beer/models/Beer";
import { beerService } from "./components/beer/services/BeerService";

const App: React.FC = () => {
  const [beer, setBeer] = useState<Beer>();

  useEffect(() => {
    initBeer();
  }, []);

  useEffect(() => {
    if (beer) {
      initCarbonator();
      initStyle();
    }
  }, [beer]);

  const initBeer = () => {
    if (!beer) {
      setBeer(beerService.get());
      setTimeout(initBeer, 500);
    }
  };

  const initCarbonator = () => {
    const element = document.getElementById("beer-background");

    if (element && beer?.theme?.data?.attributes?.carbonator) {
      new Carbonator(element, beer?.theme?.data?.attributes?.carbonator);
    } else {
      setTimeout(initCarbonator, 500);
    }
  };

  const initStyle = () => {
    const app = document.getElementById("app");
    app.style.cssText = `
    --ion-color-primary: ${beer?.theme?.data?.attributes?.colors?.primary};
    --ion-color-secondary: ${beer?.theme?.data?.attributes?.colors?.header};
    `;
  };

  return (
    <IonApp id="app">
      <IonReactRouter>
        <IonSplitPane contentId="main" when="null">
          <Navigation />

          <IonRouterOutlet id="main">
            <IonPage>
              <Head />
              <Header />

              <IonContent fullscreen>
                <div id="beer-background" />

                <IonRow id="beer-background-content">
                  <IonCol offsetMd="2" sizeMd="8">
                    <Route path="/" exact={true}>
                      <Redirect to="/category/home" />
                    </Route>

                    <Route path="/category/:category" exact={true}>
                      <Category />
                    </Route>

                    <Route path="/article/:slug" exact={true}>
                      <Article />
                    </Route>

                    <Route path="/registration/:slug" exact={true}>
                      <Registration />
                    </Route>

                    <Route path="/registration/:slug/list" exact={true}>
                      <RegistrationList />
                    </Route>
                  </IonCol>
                </IonRow>
              </IonContent>
            </IonPage>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
