import BaseServie from "../../../services/BaseService";
import Person from "../models/Person";
import { IPersonService } from "./PersonServiceInterface";

export default class PersonService
  extends BaseServie
  implements IPersonService
{
  private RESSOURCE_URL: string;

  constructor(base_url: string = "") {
    super();
    console.debug("personService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/persons`;
  }

  public async create(value: Person): Promise<any> {
    return super.POST(this.RESSOURCE_URL, { data: value });
  }
}

export const personService = new PersonService();
