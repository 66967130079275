import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
} from "@ionic/react";

import React, { useEffect, useState } from "react";
import Beer from "../../beer/models/Beer";
import { beerService } from "../../beer/services/BeerService";

import BeerView from "../../beer/views/Modal";

import Metadata from "../models/Metadata";
import { metadataService } from "../services/MetadataService";

const HeaderView: React.FC = () => {
  const [beer, setBeer] = useState<Beer>();
  const [metadata, setMetadata] = useState<Metadata | null>(null);

  useEffect(() => {
    metadataService.get().then((result) => {
      setMetadata(result.data.attributes);
    });
  }, []);

  useEffect(() => {
    setBeer(beerService.get());
  }, [beerService.get()]);

  return (
    <IonHeader>
      <IonToolbar color="secondary">
        <IonButtons slot="start">
          <IonMenuButton />
        </IonButtons>
        <IonTitle>{metadata?.title}</IonTitle>

        <BeerView beer={beer} />
      </IonToolbar>
    </IonHeader>
  );
};

export default HeaderView;
