import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonItem,
  IonList,
  IonCardSubtitle,
  IonButton,
} from "@ionic/react";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import Event, { event as defaultEvent } from "../models/Event";
import Person from "../models/Person";
import Registration from "../models/Registration";

import { eventService } from "../services/EventService";
import { registrationService } from "../services/RegistrationService";

const RegistrationListView: React.FC = () => {
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const [event, setEvent] = useState<Event>(defaultEvent);
  const [registrations, setRegistrations] = useState<Array<Registration>>([]);

  useEffect(() => {
    eventService.getBySlug(slug).then((result) => {
      const value = result.data.find((e) => e.attributes.slug === slug);
      setEvent({ ...value?.attributes, id: value.id });
    });

    registrationService.getByEventSlug(slug).then((result) => {
      setRegistrations(result.data.map((r) => r.attributes));
    });
  }, [slug]);

  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{event.title}</IonCardTitle>
        <IonCardSubtitle>Registrierte Teams</IonCardSubtitle>
      </IonCardHeader>

      <IonCardContent>
        <IonList>
          {registrations.map((registration) => (
            <IonItem>
              <IonLabel>{registration.name}</IonLabel>
            </IonItem>
          ))}
        </IonList>

        <IonButton routerLink={`/registration/${slug}`}>
          Registrieren
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default RegistrationListView;
