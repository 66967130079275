import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Navigation from "../models/Navigation";
import { navigationService } from "../services/NavigationService";

const NavigationView: React.FC = () => {
  const location = useLocation();
  const [navigations, setNavigations] = useState<Array<Navigation>>();

  useEffect(() => {
    navigationService.get().then((result) => {
      setNavigations(result.data.map(n => n.attributes));
    });
  }, []);

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="navigation">
          {navigations?.map((item, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={location.pathname === item.url ? "selected" : ""}
                  routerLink={item.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon name={item.icon}></IonIcon>
                  <IonLabel>{item.label}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default NavigationView;
