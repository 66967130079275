import BaseServie from "../../../services/BaseService";
import StrapiCollectionType from "../../../services/StrapiCollectionType";
import Event from "../models/Event";
import { IEventService } from "./EventServiceInterface";
import qs from "qs";

export default class EventService extends BaseServie implements IEventService {
  private RESSOURCE_URL: string;

  constructor(base_url: string = "") {
    super();
    console.debug("eventService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/events`;
  }

  public async getBySlug(slug: string): Promise<StrapiCollectionType<Event>> {
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        sort: ["date"],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }
}

export const eventService = new EventService();
