import BaseServie from "../../../services/BaseService";
import StrapiSingleType from "../../../services/StrapiSingleType";
import Metadata from "../models/Metadata";
import { IMetadataService } from "./MetadataServiceInterface";
import qs from 'qs';

export default class MetadataService extends BaseServie implements IMetadataService {
  private RESSOURCE_URL: string;

  constructor(base_url: string = "") {
    super();
    console.debug("metadataService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/metadata`;
  }

  public async get(): Promise<StrapiSingleType<Metadata>> {
    const query = qs.stringify(
      {
        populate: ['favicon'], 
      },
      {
        encodeValuesOnly: true,
      }
    );

    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }
}

export const metadataService = new MetadataService();
