import BaseServie from "../../../services/BaseService";
import { IBeerService } from "./BeerServiceInterface";
import qs from "qs";
import StrapiCollectionType from "../../../services/StrapiCollectionType";
import Beer from "../models/Beer";

export default class BeerService extends BaseServie implements IBeerService {
  private RESSOURCE_URL: string;
  private beer: Beer = null;

  constructor(base_url: string = "") {
    super();
    console.debug("beerService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/beers`;

    this.setRandomForTheme()
  }

  public get(): Beer {
    return this.beer;
  }

  private async getAll(): Promise<StrapiCollectionType<Beer>> {
    const query = qs.stringify(
      {
        populate: ["article", "theme"],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }

  private async setRandomForTheme(): Promise<Beer> {
    const isDark = window.matchMedia("(prefers-color-scheme: dark").matches;

    return this.getAll().then((beers) => {
      const results = beers?.data?.filter(
        (b) => !isDark || b.attributes?.theme?.data?.attributes?.isDark
      );

      const index = Math.floor(Math.random() * results.length);
      this.beer = results[index].attributes;
      return this.beer;
    });
  }
}

export const beerService = new BeerService();
