import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonRow,
} from "@ionic/react";

import Article from "../models/Article";

interface IArticleCardComponent {
  article: Article;
  back?: JSX.Element;
}

const ArticleCardComponent: React.FC<IArticleCardComponent> = (
  props: IArticleCardComponent
) => {
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <IonRow>
            {props.back}
            <p>{props?.article?.title}</p>
          </IonRow>
        </IonCardTitle>
      </IonCardHeader>

      <IonCardContent >
        <div dangerouslySetInnerHTML={{__html: props?.article?.content || ""}} />
      </IonCardContent>
    </IonCard>
  );
};

export default ArticleCardComponent;
