import BaseServie from "../../../services/BaseService";
import StrapiCollectionType from "../../../services/StrapiCollectionType";
import Article from "../models/Article";
import { IArticleService } from "./ArticleServiceInterface";
import qs from "qs";

export default class ArticleService
  extends BaseServie
  implements IArticleService
{
  private RESSOURCE_URL: string;

  constructor(base_url: string = "") {
    super();
    console.debug("articleService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/articles`;
  }

  public async getByCategory(category: string): Promise<StrapiCollectionType<Article>> {
    const query = qs.stringify(
      {
        fields: [
          'date', 
          'description', 
          'hold_on_top', 
          'slug', 
          'title'
        ],
        filters: {
          categories: {
            slug: {
              $eq: category,
            },
          },
        },
        sort: [
          {'hold_on_top': 'desc'}, 
          {'date': 'desc'}
        ],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }

  public async getBySlug(slug: string): Promise<StrapiCollectionType<Article>> {
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        sort: ['date'],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }
}

export const articleService = new ArticleService();
