import {
  IonButton,
  IonCol,
  IonContent,
  IonFabButton,
  IonIcon,
  IonModal,
  IonRow,
} from "@ionic/react";

import { beerOutline } from "ionicons/icons";
import { useState } from "react";
import ArticleCardComponent from "../../articles/components/ArticleCard";
import Beer from "../models/Beer";

interface IBeerView {
  beer: Beer;
}

const BeerView: React.FC<IBeerView> = (props: IBeerView) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <IonFabButton
        color="primary"
        onClick={() => setOpen(true)}
        size="small"
        slot="end"
      >
        <IonIcon icon={beerOutline} />
      </IonFabButton>

      <IonModal isOpen={open} onDidDismiss={() => setOpen(false)}>
        <IonContent>
          <ArticleCardComponent
            article={props?.beer?.article?.data?.attributes}
          />

          <IonRow>
            <IonCol>
              <IonButton onClick={() => setOpen(false)}>Schliessen</IonButton>
            </IonCol>
          </IonRow>
        </IonContent>
      </IonModal>
    </>
  );
};

export default BeerView;
