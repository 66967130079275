import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonRow,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

import Article from "../models/Article";
import { articleService } from "../services/ArticleService";

const CategoryView: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const [articles, setArticles] = useState<Array<Article>>([]);

  useEffect(() => {
    articleService.getByCategory(category).then((result) => {
      setArticles(result.data.map((a) => a.attributes));
    });
  }, [category]);

  const getUrlSlug = (slug: string) => {
    return `/article/${slug}`;
  };

  return (
    <IonRow>
      {articles?.map((article) => (
        <IonCol size="12" sizeMd="6" key={article.slug}>
          <IonCard button href={getUrlSlug(article?.slug)}>
            <IonCardHeader>
              <IonCardTitle>{article?.title}</IonCardTitle>
              <IonCardSubtitle>{article?.description}</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
              <IonButton routerLink={getUrlSlug(article?.slug)}>
                Weiter lesen
              </IonButton>
            </IonCardContent>
          </IonCard>
        </IonCol>
      ))}
    </IonRow>
  );
};

export default CategoryView;
