import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonLabel,
  IonItem,
  useIonToast,
  IonCheckbox,
} from "@ionic/react";

import { closeCircle } from "ionicons/icons";

import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useForm, Controller } from "react-hook-form";

import party from "party-js";

import Event, { event as defaultEvent } from "../models/Event";
import Person from "../models/Person";
import Registration from "../models/Registration";

import { eventService } from "../services/EventService";
import { personService } from "../services/PersonService";
import { registrationService } from "../services/RegistrationService";

const MESSAGE_SUCCESS = "❤️-Dank für die Anmeldung, weitere Infos folgen. 🥳";

interface IRegistration {
  registration: Registration;
  person: Person;
}

const RegistrationView: React.FC = () => {
  const history = useHistory();
  const [present, dismiss] = useIonToast();
  const { control, handleSubmit } = useForm<IRegistration>();
  const { slug } = useParams<{ slug: string }>();
  const [confirm, setConfirm] = useState(false)
  const [event, setEvent] = useState<Event>(defaultEvent);

  useEffect(() => {
    eventService.getBySlug(slug).then((result) => {
      const value = result.data.find((e) => e.attributes.slug === slug);
      setEvent({ ...value?.attributes, id: value.id });
    });
  }, [slug]);

  const makeparty = () => {
    const element = document.getElementById("registration-header");

    if (element) {
      party.confetti(element);

      const timeout = Math.random() * 1000;
      setTimeout(makeparty, timeout);
    }
  };

  const save = async (data) => {
    const registrationResult = await registrationService.create({
      ...data.registration,
      event: event.id,
    });

    const personResult = await personService.create({
      ...data.person,
      registrations: registrationResult.data.id,
    });

    Promise.all([registrationResult, personResult])
      .then((value) => {
        console.info("registration successful", value);
        makeparty();

        present({
          buttons: [{ icon: closeCircle, handler: () => dismiss() }],
          color: "success",
          duration: 5000,
          message: MESSAGE_SUCCESS,
        });

        setTimeout(history.push, 5000, "/");
      })
      .catch((error) => {
        console.error("registration unsuccessful", error);
      });
  };

  return (
    <IonCard>
      <IonCardHeader id="registration-header">
        <IonCardTitle>{event.title}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
        <form onSubmit={handleSubmit(save)}>
          <IonItem>
            <IonLabel position="floating">Gruppenname</IonLabel>
            <Controller
              name="registration.name"
              control={control}
              render={({ field }) => (
                <IonInput
                  {...field}
                  onIonChange={field.onChange}
                  required
                />
              )}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Vorname</IonLabel>
            <Controller
              name="person.firstname"
              control={control}
              render={({ field }) => (
                <IonInput
                  {...field}
                  onIonChange={field.onChange}
                  required
                />
              )}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Name</IonLabel>
            <Controller
              name="person.familyname"
              control={control}
              render={({ field }) => (
                <IonInput
                  {...field}
                  onIonChange={field.onChange}
                  required
                />
              )}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Mail</IonLabel>
            <Controller
              name="person.mail"
              control={control}
              render={({ field }) => (
                <IonInput
                  {...field}
                  onIonChange={field.onChange}
                  required
                  type="email"
                />
              )}
            />
          </IonItem>

          <IonItem>
            <IonLabel position="floating">Telefon</IonLabel>
            <Controller
              name="person.telephone"
              control={control}
              render={({ field }) => (
                <IonInput
                  {...field}
                  onIonChange={field.onChange}
                  pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                  required
                  type="tel"
                />
              )}
            />
          </IonItem>

          <IonItem>
            <IonLabel >Ich melde uns an und bestätige die <a target="_blank" href="https://m.uschle.ch/article/voelkerballturnier-regeln">AGB</a></IonLabel>
            <Controller
              control={control}
              name="registration.confirm"
              render={({ field: { value, onChange } }) => (
                <IonCheckbox
                  checked={value}
                  slot="start"
                  onIonChange={({ detail: { checked } }) => { onChange(checked); setConfirm(checked) }}
                />
              )}
            />
          </IonItem>

          <IonButton color="primary" disabled={!confirm} type="submit">
            Registrieren
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default RegistrationView;
