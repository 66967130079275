import { IonFabButton, IonIcon } from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ArticleCard from "../components/ArticleCard";

import Article from "../models/Article";
import { articleService } from "../services/ArticleService";

const ArticleView: React.FC = () => {
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const [article, setArticle] = useState<Article>();

  useEffect(() => {
    articleService.getBySlug(slug).then((result) => {
      setArticle(
        result.data.find((a) => a.attributes.slug === slug)?.attributes
      );
    });
  }, [slug]);

  const back = () => (
    <IonFabButton onClick={() => history.goBack()} size="small">
      <IonIcon icon={arrowBackOutline} />
    </IonFabButton>
  );

  return <ArticleCard article={article} back={back()} />;
};

export default ArticleView;
