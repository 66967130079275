import axios, { AxiosRequestConfig } from "axios";
import { closeCircle } from "ionicons/icons";

const MESSAGE_ERROR = "Oops something went wrong 🤷";

export default class BaseServie {
  protected BASE_URL: string | undefined = "https://api.uschle.ch/api";
  protected deleteConfig: AxiosRequestConfig;
  protected getConfig: AxiosRequestConfig;
  protected postConfig: AxiosRequestConfig;
  protected putConfig: AxiosRequestConfig;

  constructor() {
    console.debug("baseService::constructor", this.BASE_URL);

    this.deleteConfig = {
      method: "DELETE",
    };
    this.getConfig = {
      method: "GET",
    };
    this.postConfig = {
      method: "POST",
    };
    this.putConfig = {
      method: "PUT",
    };
  }

  protected async DELETE(
    url: string,
    config: AxiosRequestConfig = this.deleteConfig
  ) {
    const encodedUrl = encodeURI(url);
    console.info("API Call:=", url);

    try {
      const response = await axios.delete(encodedUrl, config);
      console.info("response", response);
      return response.data;
    } catch (error) {
      this.errorhandling(error);
    }
  }

  protected async GET(
    url: string,
    config: AxiosRequestConfig = this.getConfig
  ) {
    const encodedUrl = encodeURI(url);
    console.info("API Call:=", url);

    try {
      const response = await axios.get(encodedUrl, config);
      console.info(response.config.url, response);
      return response.data;
    } catch (error) {
      return this.errorhandling(error);
    }
  }

  protected async POST(
    url: string,
    data: any,
    config: AxiosRequestConfig = this.postConfig
  ) {
    const encodedUrl = encodeURI(url);
    console.info("API Call:=", url, "data", data);

    try {
      const response = await axios.post(encodedUrl, data, config);
      console.info("response", response);
      return response.data;
    } catch (error) {
      this.errorhandling(error);
    }
  }

  protected async PUT(
    url: string,
    data: any,
    config: AxiosRequestConfig = this.putConfig
  ) {
    const encodedUrl = encodeURI(url);
    console.info("API Call:=", url, "data", data);
    try {
      const response = await axios.put(encodedUrl, data, config);
      console.info("response", response);
      return response.data;
    } catch (error) {
      this.errorhandling(error);
    }
  }

  private errorhandling(error: any) {
    // Rethrow error
    this.toast();
    throw error;
  }

  private async toast() {
    const toast = document.createElement("ion-toast");

    toast.buttons = [
      {
        icon: closeCircle,
        role: "cancel",
      },
    ];

    toast.color = "danger";
    toast.duration = 0;
    toast.message = MESSAGE_ERROR;

    document.body.appendChild(toast);
    return toast.present();
  }
}
