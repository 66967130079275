import { useEffect } from "react";

import { metadataService } from "../services/MetadataService";

const HeadView: React.FC = () => {
  useEffect(() => {
    metadataService.get().then((result) => {
      setFavicon(result.data.attributes.favicon?.data?.attributes?.url);
      setTitle(result.data.attributes.title);
    });
  }, []);

  const setFavicon = (url: string | undefined) => {
    let favicon = document.createElement("link");
    favicon.setAttribute("rel", "shortcut icon");
    favicon.setAttribute("href", `${url}` || "");

    document.querySelector("head")?.appendChild(favicon);
  };

  const setTitle = (title: string | undefined) => {
    document.title = title || "";
  };

  return <></>;
};

export default HeadView;
