import qs from "qs";
import BaseServie from "../../../services/BaseService";
import StrapiCollectionType from "../../../services/StrapiCollectionType";
import Registration from "../models/Registration";
import { IRegistrationService } from "./RegistrationServiceInterface";

export default class RegistrationService
  extends BaseServie
  implements IRegistrationService
{
  private RESSOURCE_URL: string;

  constructor(base_url: string = "") {
    super();
    console.debug("registrationService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/registrations`;
  }

  public async create(value: Registration): Promise<any> {
    return super.POST(this.RESSOURCE_URL, { data: value });
  }

  public async getByEventSlug(
    slug: string
  ): Promise<StrapiCollectionType<Registration>> {
    const query = qs.stringify(
      {
        filters: {
          event: {
            slug: {
              $eq: slug,
            },
          },
          person: {
            id: {
              $notNull: true,
            },
          },
        },
        sort: [{ createdAt: "asc" }],
      },
      {
        encodeValuesOnly: true,
      }
    );

    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }
}

export const registrationService = new RegistrationService();
