import BaseServie from "../../../services/BaseService";
import StrapiCollectionType from "../../../services/StrapiCollectionType";
import Navigation from "../models/Navigation";
import { INavigationService } from "./NavigationServiceInterface";
import qs from 'qs';

export default class NavigationService extends BaseServie implements INavigationService {
  private RESSOURCE_URL: string;

  constructor(base_url: string = "") {
    super();
    console.debug("navigationService::constructor", base_url);

    this.RESSOURCE_URL = `${this.BASE_URL}/navigations`;
  }

  public async get(): Promise<StrapiCollectionType<Navigation>> {
    const query = qs.stringify(
      {
        sort: ['order'],
      },
      {
        encodeValuesOnly: true,
      }
    );
    
    return super.GET(`${this.RESSOURCE_URL}/?${query}`);
  }
}

export const navigationService = new NavigationService();
