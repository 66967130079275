import Registration from "./Registration";

export default interface Event {
  date: any;
  id: Number;
  registrations?: Array<Registration>;
  slug: string;
  title: string;
}

export const event = {
  date: "",
  id: 0,
  registrations: [],
  slug: "",
  title: "",
};
